.bigButton {
  background-color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.29);
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--default-border-radius);
}
.bigButton .icon {
  aspect-ratio: 1/1;
  height: 100%;
}
.bigButton:hover {
  background-color: #e1e1e1;
}
.bigButton:active {
  background-color: #ced4d6;
  transform: scale(0.95);
}

.bigButtonTitle {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.maskImage {
  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
          mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}

.rdw-dropdownoption-default {
  background-color: #e1e1e1;
  color: black;
}/*# sourceMappingURL=hjem.css.map */