.uploading-container {
    position: fixed;
    bottom: 10px;
    left: 50%;
    width: min(90%, 400px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    border-radius: var(--default-border-radius);
    transform: translateX(-50%);
    padding: var(--default-padding);
}

.loading-bar {
    //height: 1em;
    background-color: var(--background-break);
    border-radius: var(--default-border-radius);
    padding: 5px;
}

.loading-bar-inner {
    height: 1em;
    min-width: 10px;
    background-color: var(--contrastColor);
    border-radius: var(--default-border-radius);
    transition: width 0.5s ease;
}

// Make into an actual PDF file which is building?
.pdf-loading {
    width: min(40vw, 400px) !important;
    height: min(40vw, 400px) !important;
    margin: auto;
    color: var(--contrastColor) !important;
    //height: max(100v;
}

.loading-image {
    width: 60vw;
    max-height: 30vh;
    object-fit: contain;
}