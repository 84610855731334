.menu-button {
  background: none;
  border-radius: 0;
  width: 100%;
  padding-block: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menu-button:hover {
  background-color: var(--ion-color-step-50);
}/*# sourceMappingURL=menu.css.map */