.top-menu input {
  font-size: 1.2em;
}
.top-menu button {
  padding: 1em;
}
.top-menu * > svg {
  width: 1.5em;
  height: 1.5em;
}

.icon-only-button {
  background: none;
  border: none;
  box-shadow: none;
  border: none;
}
.icon-only-button:hover {
  border: none;
}
.icon-only-button:hover svg {
  filter: drop-shadow(0 0 0.3em var(--contrastColor));
}
.icon-only-button.active {
  color: var(--contrastColor);
}
.icon-only-button.active * {
  fill: var(--contrastColor);
}
.icon-only-button.active:hover {
  border: none;
}
.icon-only-button.active:hover svg {
  filter: drop-shadow(0 0 0.5em rgb(255, 255, 255));
}
.icon-only-button svg {
  width: 1.5em !important;
  height: 1.5em !important;
}

.phone-header-button-icon {
  width: 1.5em !important;
  height: 1.5em !important;
}/*# sourceMappingURL=header.css.map */