.render-pdf {
  width: 100%;
  height: 100%;
  display: flex;
}

.render-pdf-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  background: white;
}/*# sourceMappingURL=pdf.css.map */