ion-toolbar {
    --padding-bottom: 10px;
    --padding-top: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
}

/*:root {
    
    --ion-background-color: #1cf472 !important;
    --ion-background-color-rgb: 0, 15, 6 !important;

    --ion-text-color: #ffffff !important;
    --ion-text-color-rgb: 255, 255, 255 !important;

    --ion-color-step-50: #0d1b12 !important;
    --ion-color-step-100: #1a271f !important;
    --ion-color-step-150: #26332b !important;
    --ion-color-step-200: #333f38 !important;
    --ion-color-step-250: #404b44 !important;
    --ion-color-step-300: #4d5751 !important;
    --ion-color-step-350: #59635d !important;
    --ion-color-step-400: #666f6a !important;
    --ion-color-step-450: #737b76 !important;
    --ion-color-step-500: #808783 !important;
    --ion-color-step-550: #8c938f !important;
    --ion-color-step-600: #999f9b !important;
    --ion-color-step-650: #a6aba8 !important;
    --ion-color-step-700: #b3b7b4 !important;
    --ion-color-step-750: #bfc3c1 !important;
    --ion-color-step-800: #cccfcd !important;
    --ion-color-step-850: #d9dbda !important;
    --ion-color-step-900: #e6e7e6 !important;
    --ion-color-step-950: #f2f3f3 !important;
}*/

/* styles.css or your stylesheet */

.custom-ionfablist {
    right: 0% !important; /* Adjust the right positioning to 50% of its width */
    transform: translateX(0%); /* Move the list to the right by half of its width */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ionFabButton {
    margin: 5px;
    width: 100%;
    max-width: 80vw;

    > svg {
        width: 24px;
        height: 24px;
    }
}

ion-modal > .ion-page {
    display: flex;
    flex-direction: column;
}
