@charset "UTF-8";
.time-card,
.simple-time-card-time,
.orange-card,
.card, .simple-card {
  background-color: var(--background-break);
  padding: 10px 20px;
  border-radius: var(--default-border-radius);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.orange-card,
.simple-time-card-time {
  color: var(--Theme-Color);
  background-color: var(--contrastColor);
}
.orange-card > *,
.simple-time-card-time > * {
  color: var(--Theme-Color);
}

.simple-time-card-time {
  width: 85px;
  min-width: 85px;
  text-wrap: nowrap;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.time-input {
  /* This will ensure the inputs can fit two digits and a little extra for comfort. Adjust as needed. */
  width: 1.8em !important;
  text-align: center;
  /* Remove the arrows on number inputs in Firefox */
}

.even {
  background-color: hsl(222, 19%, 11%);
}

.odd {
  background-color: hsl(222, 19%, 15%);
}

.big-time-input {
  width: 30vw;
  text-align: center;
  height: 30vw;
  font-size: 20pt;
}

.MuiDialog-paperFullScreen {
  background-color: transparent !important;
}

.good {
  background-color: var(--good);
  color: var(--text);
}
.good > * {
  color: var(--text);
}

.ansatt-kalender-felt {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.arbeidsplan-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  border-radius: var(--default-border-radius);
}
@media (min-width: 600px) {
  .arbeidsplan-container {
    align-items: center;
  }
}

.arbeidsplan-kalender-ny {
  min-width: 1100px;
  max-width: 1100px;
  width: 100%;
}

.dag-kalender-felt {
  position: relative;
  width: 90px;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 60px;
  max-width: 80px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calender-background {
  background-color: white;
  color: black;
  text-align: center;
  z-index: 5;
}

.calender-background-contrast {
  background-color: var(--contrastColor);
  color: var(--Theme-Color);
}
.calender-background-contrast > * {
  color: var(--Theme-Color);
}

.dag-kalender-felt-add {
  width: 90px;
  height: 60px;
  max-width: 80px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
  color: none;
  border: 1px solid var(--contrastColor);
  transition: all 0.2s ease-in-out;
  opacity: 0.1;
}
.dag-kalender-felt-add:hover {
  border: 1px solid var(--contrastColor);
  color: var(--contrastColor);
  cursor: pointer;
  content: "+";
  opacity: 1;
}
.dag-kalender-felt-add:hover * {
  display: block;
}
@media (max-width: 600px) {
  .dag-kalender-felt-add {
    border: 1px solid var(--contrastColor);
    color: var(--contrastColor);
    cursor: pointer;
    content: "+";
    opacity: 1;
  }
  .dag-kalender-felt-add * {
    display: block;
  }
}

.arbeidsplan-kalendender-uke-område {
  height: 60px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.dag-combination-kalender-felt {
  z-index: 5;
  transition: all 0.2s ease-in-out;
}
.dag-combination-kalender-felt:hover .dato-kalender-felt-add-container {
  height: 30px;
}
.dag-combination-kalender-felt:hover .dato-kalender-felt-add {
  padding-top: 40px;
  top: -40px;
}

.dato-kalender-felt {
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.dato-kalender-felt:hover {
  cursor: pointer;
  outline: 1px solid var(--contrastColor);
}

.dato-kalender-felt-add-container {
  height: 0px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.dato-kalender-felt-add-container .dato-kalender-felt-add:hover {
  cursor: pointer;
  outline: 1px solid var(--contrastColor);
  opacity: 1;
}

.dato-kalender-felt-add {
  text-align: center;
  border: 1px solid var(--contrastColor);
  border-radius: var(--default-border-radius);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0px;
  top: -60px;
  transition: all 0.2s ease-in-out;
  opacity: 0.1;
}

.selected-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--contrastColor);
}

.weekend {
  color: red;
}
.weekend > * {
  color: red;
}

.timer-button {
  background: none;
  outline: 1px solid var(--text);
  border-radius: var(--default-border-radius-small);
  height: calc(100% - 10px);
}
.timer-button p {
  font-size: large;
}

.time-top {
  background: var(--background-contrast);
  padding-block: 5px;
  padding-inline: 20px;
  border-radius: var(--default-border-radius-small) var(--default-border-radius-small) 0 0;
}

.time-bottom {
  background: var(--background-contrast-opaque);
  padding-block: 10px;
  padding-inline: 20px;
  border-radius: 0 0 var(--default-border-radius-small) var(--default-border-radius-small);
}
@media (max-width: 600px) {
  .time-bottom {
    padding-top: 20px;
  }
}/*# sourceMappingURL=timetracker.css.map */