.clientCard {
    background: whitesmoke;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
    border-radius: 200px;
    color: black;
    padding: 10px 40px;

    p, h1, h2, h3, h4, h5, h6 {
        color: black;
    }
}


.dateSelectedText {
    color: var(--input-background);
}

.dateSelected {
    background-color: var(--input-background);
    color: var(--input-background);
}