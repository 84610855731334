.utstyrs-card {
    background: var(--background-break);
    width: 100%;
    //height: 100%;
    //padding: 1rem;
    border-radius: var(--default-border-radius);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    color: var(--text);
    overflow: hidden;
    gap: 0px;

    &:hover {
        transform: translateY(-0.5rem);
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    }

    /*p, h1, h2, h3, h4, h5, h6 {
        color: var(--text);
    }*/
}

.utstyr-bilde {
    width: 124px;
    height: 124px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem 0 0 0.5rem;
}

.edit-icon {
    width: 38px;
    height: 38px;
    object-fit: cover;
    object-position: center;
}

.utstyr-edit-bilde {
    width: 100%;
    height: 60%;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
    aspect-ratio: 1;
}

.edit-icon * * * * * {
    fill: white;
    color: white;
}

.service-card {
    background: var(--background-break);
    width: 100%;
    //padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    color: var(--text);
    padding: 10px;

    &:hover {
        transform: translateY(-0.5rem);
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    }

    p, h1, h2, h3, h4, h5, h6 {
        color: var(--text);
    }
}

.add-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    object-position: center;
}

.profile-image {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}