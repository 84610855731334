.clientCard {
  background: whitesmoke;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 200px;
  color: black;
  padding: 10px 40px;
}
.clientCard p, .clientCard h1, .clientCard h2, .clientCard h3, .clientCard h4, .clientCard h5, .clientCard h6 {
  color: black;
}

.dateSelectedText {
  color: var(--input-background);
}

.dateSelected {
  background-color: var(--input-background);
  color: var(--input-background);
}/*# sourceMappingURL=clients.css.map */