:root {
    --default-gap: 20px;
    --default-small-gap: 10px;
    --default-big-gap: 20px;
    --default-padding: 20px;
    --default-border-radius: 20px;
    --default-border-radius-small: 10px;

    --active-color: #009dff;
    --diff-color: #e28f00;

    --Theme-Color: hsl(222, 19%, 11%);
    --background-color: hsla(222, 18%, 11%, 0.702);
    --background-break: #ffffff0d; //--background-break: hsla(0, 0%, 100%, 0.031);
    --contrastColor: #fab005;
    --text: hsla(0, 0%, 100%, 0.906);
    --contrastText: hsl(222, 19%, 11%);
    --good: hsla(126, 54%, 45%, 0.6);
    --bad: hsl(0, 72%, 55%, 0.7);
    --input-background: hsl(222, 19%, 11%);
    --background-contrast: black;
    --background-contrast-opaque: #000000b0;

    --padding-top: 0px;
    --padding-bottom: 0px;
    // ../../assets/Background.png
    --background-image: src("assets/Background.png");
}

.app-content {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}
.content-ltr {
    min-height: 100vh;
}

#map {
    height: 400px;
    width: 100%;
    border-radius: var(--default-border-radius);
}

#map-no-border {
    height: 300px;
    width: 100%;
}

#my-cool-map {
    z-index: 9999; // or any sufficiently low value
}

.page {
    padding-top: 40px 20px;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    color: white;
    width: fit-content;
    font-family: "Poppins", sans-serif;
    transition: all 0.2s ease-in-out;
    //font-family: "Poppins", sans-serif;
}

.text-black {
    color: black;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: black;
    }
}

a {
    text-decoration: underline;
    cursor: pointer;
    color: var(--contrastColor);
}

.big-text {
    font-size: 1.3em !important;
    //font-weight: 500;
}

.bold {
    font-weight: 700;
}

h1 {
    font-size: 14pt;
    font-weight: 500;

    @media (max-width: 600px) {
        font-size: 20px;
    }
}

h2 {
    font-size: 14pt;
    font-weight: 600;

    @media (max-width: 600px) {
        font-size: 16px;
    }
}

h3 {
    font-size: 10pt;
    font-weight: 700;

    @media (max-width: 600px) {
        font-size: 12px;
    }
}

h4 {
    font-size: 1em;
    font-weight: 500;

    @media (max-width: 600px) {
        font-size: 0.8em;
    }
}

h5 {
    font-size: 12px;
    font-weight: 500;
}

p {
    font-size: 12px;
    font-weight: 500;

    @media (max-width: 600px) {
        font-size: 10pt;
    }
}

.small-text {
    font-size: 10px !important;
}

textarea {
    font-size: 12pt;
    font-weight: 500;
    width: stretch;
    height: stretch;
    background: var(--input-background);
    border-radius: 2px 2px 0 0;
    color: var(--text);
    border: none;
    //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0;

    min-height: 100px;

    @media (max-width: 600px) {
        font-size: 10pt;
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input {
    font-family: "Montserrat", sans-serif;
    /*font-size: 12pt;
    font-weight: 500;
    width: stretch;
    background-color: none;
    color: var(--text);
    border: none;
    //border-bottom: 1px solid var(--active-color);
    transition: all 0.2s ease-in-out;
    outline: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);*/
    width: 100%;
    min-width: 20px;
    outline: none;
    border: none;
    background: none;
    transition: all 0.2s ease-in-out;

    &:active,
    &:focus {
        border-bottom: 1px solid var(--contrastColor);
        //border-bottom: 3px solid var(--diff-color);
    }

    @media (max-width: 600px) {
        font-size: 10pt;
    }
}

hr {
    width: 100%;
    border: 1px solid var(--background-break);
}

.suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--input-background);
    width: 100%;
    border-radius: var(--default-border-radius);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 5;
    max-height: 400px;
    overflow-y: scroll;

    .suggestion {
        padding: 20px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        border-bottom: 1px solid var(--contrastColor);
    }
}

.button-background {
    background: var(--background-break);
}

.input-background {
    background: var(--input-background);
}

.noBackground {
    background: none !important;
}

.checkbox {
    padding: 10px;
    background: var(--input-background);
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    outline: rgba(255, 255, 255, 0.4) 1px solid;

    svg {
        //fill: var(--text);
        width: 20px;
        height: 20px;

        polyline {
            fill: none;
            //stroke: #231f20;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 4px;

            fill: none;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
        }
    }
}

.checkbox.checked {
    background: var(--contrastColor);

    svg {
        //animation: check 5s ease-in-out forwards;
        fill: var(--input-background);
        animation: checkScale 0.5s ease-in-out forwards;

        polyline {
            stroke: var(--input-background);
            fill: none;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: check 2.2s ease-in-out forwards;
        }
    }
}

//keyframe check svg to animate the checkmark
@keyframes check {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes checkScale {
    0% {
        transform: scale(0.7);
    }
    80% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.customInput {
    font-size: 12pt;
    font-weight: 500;
    width: stretch;
    min-height: 3em;
    background-color: var(--input-background);
    border-radius: 20px;
    color: var(--text);
    border: none;
    //border-bottom: 1px solid var(--active-color);
    transition: all 0.2s ease-in-out;
    outline: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;

    &:active,
    &:focus {
        svg {
            fill: var(--active-color);
        }
        //border-bottom: 3px solid var(--diff-color);
    }

    > * {
        color: "red";
    }

    @media (max-width: 600px) {
        font-size: 10pt;
    }

    &[type="checkbox"] {
        border-radius: 0;
        width: 20px;
        height: 20px;
        padding: 0;
        background-color: none;
    }
}

input:user-invalid {
    border-bottom: 1px solid var(--bad);
}

input:valid {
    border-bottom: 1px solid var(--good);
}

//customInput_label customInput_label_active
.customInput_label {
    position: absolute;
    top: var(--default-padding);
    left: var(--default-padding);
    z-index: 2; /* ensure label is above the SVG */
    //padding: 0 5px;
    color: rgba(255, 255, 255, 0.579);
    font-size: 1em;
    transition: all 0.2s ease-in-out;
}

.customEditInput_label {
    position: absolute;
    top: var(--default-padding);
    left: 0;
    z-index: 2; /* ensure label is above the SVG */
    //padding: 0 5px;
    color: rgba(255, 255, 255, 0.579);
    font-size: 1em;
    transition: all 0.2s ease-in-out;
}

.custom-number-input-label {
    position: absolute;
    top: 2px;
    left: 50%;
    z-index: 2; /* ensure label is above the SVG */
    //padding: 0 5px;
    font-size: 0.9em;
    transition: all 0.2s ease-in-out;
    transform: translateX(-50%);
}

.customInput_label_active {
    top: 4px;
    left: var(--default-padding);
    font-size: 12px;
    color: var(--contrastColor);
}

.customEditInput_label_active {
    top: -0.8em;
    left: 0;
    font-size: 12px;
    color: var(--contrastColor);
}

.searchWithPopup-label {
    position: absolute;
    top: 4px;
    left: var(--default-padding);
    font-size: 12px;
    color: var(--contrastColor);
}

.customCheckboxInput {
    padding: 8px;

    & .circleIcon {
        scale: 0.7;
    }

    & .checkbox {
        scale: 0.7;
    }
}

select {
    width: fit-content;
    padding: 10px 10px;
    background: var(--background-break);
    outline: none;
    border: none;
    border-radius: 20px;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    padding-right: 2rem;
    background-position: right 10px top 50%;

    color: var(--text);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 12px;

    option {
        color: black;
        background: white;
        font-size: 12px;
    }
}

.button-list-button-label {
    position: absolute;
    transition: all 0.2s ease-in-out;

    top: 4px;
    left: 80px;
    font-size: 12px;
    color: var(--contrastColor);
}

.button-list-button-label-not-active {
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: var(--contrastColor);
}

.customDropdown {
    //width: 100%;
    min-width: 145px;
    width: max-content; //100%;

    height: 100%;
    padding: 0px 20px;
    background: var(--background-break);
    border-radius: 4px;

    font-size: 12px;
    margin: 0;

    //outline: 1px solid white;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    svg {
        fill: var(--text);
        transition: all 0.2s ease-in-out;
        width: 20px;
        height: 20px;
    }
}

.dropdown-label {
    position: absolute;
    top: 3px;
    left: 10px;
    z-index: 2; /* ensure label is above the SVG */
    //background-color: var(--Theme-Color);
    padding: 0 5px;
    color: var(--contrastColor);
}

.søkefelt {
    display: flex;
    width: 120px;
    transition: all 0.4s ease-in-out;
    max-width: 300px;
    transform-origin: right;
    margin-left: auto;

    &:focus-within {
        width: 100% !important;
    }
}

/*.søkefelt:has(.søkefelt-input:active),
.søkefelt:has(.søkefelt-input:focus) {
    width: 100% !important;
}

.wrap .søkefelt:has(.søkefelt-input:active),
.wrap .søkefelt:has(.søkefelt-input:focus) {
    width: 50% !important;
}*/

.søkefelt-input {
    background: none !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    color: var(--text) !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100% !important;

    &:active,
    &:focus {
        border-bottom: 1px solid var(--contrastColor) !important;
    }
}

input[type="file"] {
    display: none;
}

button,
.button {
    display: flex;
    background: var(--background-break);
    padding: 10px 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    transition: all 0.5ms ease-in-out;
    color: white;
    height: fit-content;
    width: fit-content;
    //center in children
    align-items: center;
    gap: 10px;

    justify-content: center;
    align-items: center;

    svg {
        font-size: 1rem !important;
        width: 16px;
        height: 16px;

        * {
            fill: var(--text);
        }
    }

    /*& > svg {
        width: 16px;
        height: 16px;

        * {
            fill: var(--text);
        }
    }*/

    &:active {
        transform: scale(0.95);
    }
}

.number-button {
    cursor: pointer;
    display: flex;
    transition: all 100ms ease-in-out;

    &:active {
        transform: scale(0.9);
    }
}

//disabled button
button[disabled] {
    opacity: 0.3;
}

.button-round,
.round-button,
.circle-button {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--contrastColor);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.5ms ease-in-out;
    padding: 10px;
    color: rgb(0, 0, 0);

    svg {
        width: 20px;
        height: 20px;

        * {
            fill: black;
        }
    }

    &:hover {
        transform: scale(1);
        outline: var(--contrastText);
    }

    &:active {
        transform: scale(1);
        outline: #37474f;
    }
}

.upload-button {
    background: var(--active-color);
    padding: 20px 20px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    border-radius: 4px;
    transition: all 0.5ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    transition: all 0.2s ease-in-out;

    &:active {
        transform: scale(0.9);
    }
}

.circular-progress-button {
    svg * {
        fill: none;
    }
}

.buttonSelected {
    color: var(--contrastColor);
    outline: 1px solid var(--contrastColor);

    svg {
        * {
            fill: var(--contrastColor);
        }
    }
}

.button-no-style {
    display: flex;
    background: transparent;
    padding: 0px;
    box-shadow: none;
    border-radius: 0;
    transition: all 0.5ms ease-in-out;
    color: white;
    height: fit-content;
    width: fit-content;
    //center in children
    align-items: center;
    gap: 10px;
}

.content-ny {
    display: flex;
    flex-direction: column;
    //gap: var(--gap);
    padding: var(--default-padding);
    box-sizing: border-box;
    gap: var(--default-gap);
    //padding-bottom: 100px;
    //margin-bottom: 20px;
    position: relative;
    box-sizing: border-box;
    //overflow-y: scroll;
    width: stretch;
    padding-top: 0px;
    //height: 100%;

    * {
        box-sizing: border-box;
    }

    > .bigButton {
        width: 100%;
        margin: 0;
    }
}

.limit-width {
    @media (min-width: 1500px) {
        padding-right: 400px;
    }
}

.padding-bottom-for-header {
    padding-bottom: 120px;
    margin-bottom: 120px;
    height: 100%;
}

.bottom-padding {
    padding-bottom: 20px;
}

.content-ny-no-extension {
    display: flex;
    flex-direction: column;
    //gap: var(--gap);
    padding: var(--default-padding);
    box-sizing: border-box;
    gap: var(--default-gap);
    //padding-bottom: 100px;
    margin-bottom: 0px;
    position: relative;
    //overflow-y: scroll;
    //width: stretch;
    //height: 100%;

    * {
        box-sizing: border-box;
    }

    > .bigButton {
        width: 100%;
        margin: 0;
    }
}

.wrapperHeaderContent {
    & > .content-ny {
        padding-bottom: 200px;

        @media (min-width: 600px) {
            padding-bottom: 20px;
        }
    }
}

.wrapper-padding {
    padding-top: 80px;
}

.bigButton {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        margin: 0;
        height: 100%;
    }
}

.orangeButton, .orange-button {
    background: var(--contrastColor);
    color: var(--Theme-Color);
    * {
        fill: var(--Theme-Color);
    }
}

.padding {
    padding: var(--default-padding);
}

.small-padding {
    padding: var(--default-small-gap);
}

.row {
    display: flex;
    flex-direction: row;
    gap: var(--default-gap);
}

.column {
    display: flex;
    flex-direction: column;
    gap: var(--default-gap);
}

.row-size-dependant {
    display: flex;
    flex-direction: row;
    gap: var(--default-gap);

    @media (max-width: 600px) {
        flex-direction: column;
        display: flex;
    }
}

.center {
    justify-content: center;
    align-items: center;
}

.center-top {
    justify-content: center;
    align-items: flex-start;
}

.center-text {
    text-align: center;
}

.gap {
    gap: var(--default-gap);
}

.flexApart {
    display: flex;
    justify-content: space-between;
}

.center-column {
    display: flex;
    align-items: center;
}

.center-row {
    display: flex;
    justify-content: center;
}

.top-left {
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.bottom-align {
    align-items: flex-end !important;
}

.right-align {
    align-items: flex-end;
}

.left-align {
    align-items: flex-start;
}

.flex-start {
    justify-content: flex-start;
}

.gap {
    gap: var(--default-gap);
}

.small-gap {
    gap: var(--default-small-gap);
}

.big-gap {
    gap: var(--default-big-gap);
}

.noGap {
    gap: 0px;
}

.shadow {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.wrap {
    flex-wrap: wrap;
}

.align-bottom {
    align-items: baseline;
}

.stretch-height {
    height: stretch;
}

.hundre-height {
    height: 100%;
}

.stretch-width {
    width: stretch;
}

.moz-height {
    height: 100%;
}

.fit-content,
.fit {
    width: fit-content !important;
    height: fit-content !important;
}

.auto-height {
    height: auto;
}

.one-to-one {
    aspect-ratio: 1 / 1;
}

.max-content {
    width: max-content;
    height: max-content;
}

ion-toast.toast-ccc {
    --color: white;
    --background: var(--active-color);
}

ion-toast.toast-ccc::part(message) {
    font-weight: 500;
    font-size: 12pt;
    //padding: 10px
}

.delete {
    background-color: rgb(209 51 51);
}

.background-holder {
    position: fixed;
    top: 0px;
    left: 0px;
    width: stretch;
    height: stretch;
    z-index: -1;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.background-holder img {
    //filter: brightness(0.6) contrast(2) hue-rotate(0deg) saturate(1.2) sepia(0.2);
    filter: brightness(0.3);

    transform: translateY(100px) scale(1.5);
    //animation: filterBackground 30s infinite;
    @media (max-width: 600px) {
        filter: brightness(0.1);
    }
}

@keyframes filterBackground {
    0% {
        filter: brightness(1.8) contrast(2);
    }
    50% {
        filter: brightness(0.5) contrast(1);
    }
    100% {
        filter: brightness(1.8) contrast(2);
    }
}

.background-diffuser {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    //backdrop-filter: blur(100px);
    backdrop-filter: blur(17px);

    @media (max-width: 600px) {
        background: #ffffff1a;
    }
}

//Button List

.button-list {
    display: flex;
    flex-direction: column;
    gap: var(--default-small-gap);
    //padding: var(--default-padding);
    box-sizing: border-box;

    & .button-list-button {
        &:first-child {
            border-radius: 20px 20px 10px 10px;
        }

        &:last-child {
            border-radius: 10px 10px 20px 20px;
        }

        &:only-child {
            border-radius: 20px;
        }
    }
}

.button-list-button {
    background: var(--background-break);
    padding: 10px 20px;

    width: 100%;
    display: flex;
    justify-content: row;
    align-items: center;
    gap: var(--default-gap);
    position: relative;
    border-radius: 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: left;
        //white-space: nowrap;
        white-space: break-spaces;
        overflow: hidden;
        //width: fit-content;
        max-width: 100%;
        text-overflow: ellipsis;
    }

    p {
        width: fit-content;
        max-width: stretch;
        text-overflow: wrap;
        text-align: left;
    }

    img {
        width: calc(32px + 20px); //Padding accounted for
        height: calc(32px + 20px); //Padding accounted for
        border-radius: 100%;
    }
}

.rounded {
    border-radius: 20px;

    &:first-child {
        border-radius: 20px;
    }
}

.itemDropDownButton {
    &:active {
        scale: 1;
        transform: scale(1);

        outline: white 1px solid;
    }

    &:focus {
        scale: 1;
        transform: scale(1);

        outline: white 1px solid;
    }
}

.circleIcon {
    padding: 0.5em;
    border-radius: 100%;
    background: var(--contrastColor);
    width: fit-content;
    height: fit-content;

    svg,
    i,
    i::before {
        display: flex;
        width: 1.3em;
        height: 1.3em;
        font-size: 1.2em;
        justify-content: center;
        align-content: center;
        align-items: center;

        @media (min-width: 600px) {
            width: 1.3em;
            height: 1.3em;
            font-size: 1.2em;
        }
    }

    * {
        color: var(--Theme-Color);
        fill: var(--Theme-Color);
    }
}

.circleIcon.good {
    background: var(--good);

    * {
        color: var(--text);
        fill: var(--text);
    }
}

.circleIcon.recording {
    background: var(--bad);

    svg,
    i,
    i::before {
        animation: rotateBackAndFourthSlow 2s infinite;
    }

    * {
        color: var(--text);
        fill: var(--text);
    }
}

@keyframes rotateBackAndFourthSlow {
    0% {
        transform: rotate(20deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(20deg);
    }
}

.orange {
    color: var(--contrastColor) !important;
    fill: var(--contrastColor) !important;

    * {
        color: var(--contrastColor);
        fill: var(--contrastColor);
    }
}

.bottom-right-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;

    background: var(--contrastColor);
    color: var(--Theme-Color);
    font-weight: 500;
    font-size: 12pt;

    * {
        width: 24px;
        height: 24px;
        fill: var(--Theme-Color);
        * > {
            fill: var(--Theme-Color);
        }
    }
}

.bottom-right-header-button,
.bottom-right-header-button-with-header {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;

    background: var(--contrastColor);
    color: var(--Theme-Color);
    font-weight: 500;
    font-size: 12pt;

    * {
        width: 24px;
        height: 24px;
        fill: var(--Theme-Color);
        * > {
            fill: var(--Theme-Color);
        }
    }
}

.bottom-right-header-button-with-header {
    @media (max-width: 800px) {
        bottom: 140px;
    }
}

.long-line {
    width: 90%;
    margin: auto;
    height: 1px;
    background: var(--background-break);
}

//icon
.rfipbtn--bluegrey {
    background-color: transparent !important;
    border: none !important;
}
.rfipbtn {
    width: 10px !important;
    /* display: flex; */
    /* flex-flow: row nowrap; */
    min-height: 0 !important;
    /* border-radius: 2px; */
    /* cursor: pointer; */
    /* transition: box-shadow .25s,border-color .25s; */
    /* outline: 0 none; */
    /* -webkit-user-select: none; */
    /* user-select: none;*/
}

.rfipbtn--bluegrey .rfipbtn__icon {
    border: none !important;
    color: var(--contrastText) !important;
}

.rfipbtn__button {
    border: 0 none transparent;
    border-left: 1px solid #90a4ae;
    background-color: #cfd8dc;
    color: #37474f;
    display: none !important;
}

.rfipbtn__elm {
    display: flex;
    /* height: 28px; */
    /* width: 28px; */
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.rfipbtn--bluegrey .rfipbtn__del {
    background-color: transparent !important;
}

.rfipbtn__del {
    width: 18px;
    display: flex;
    height: 28px;
    align-items: center;
    justify-content: center;
    display: none !important;
    transition: background-color 0.25s;
    cursor: pointer;
}

.rfipbtn__current {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex: 0 !important;
    padding: 0px !important;
}

.rfipbtn__icon {
    margin: 0px !important;
    padding: 0;
    height: 24px !important;
    width: 24px !important;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
}

.rfip {
    position: relative;
    display: inline-block;
    margin: 0px !important;
    vertical-align: middle;
    width: 24px;
    height: 24px;

    * > {
        z-index: 0 !important;
    }
}

.header-container {
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 40px;
    padding-left: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    //width: calc(100% - 30px); DONT KNOW WHY THIS EXISTS
    width: 100%;
    box-sizing: border-box;
    z-index: 100;
    @media (min-width: 800px) {
        display: none;
    }
}

.header {
    @media (min-width: 800px) {
        display: none;
    }

    //background: linear-gradient(180deg, #282426 0%, #211f24 100%);
    --background: transparent;

    padding: 15px 20px 20px;
    gap: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    border-radius: 0px;
    box-sizing: border-box;
    //background: #211f24ab;
    backdrop-filter: blur(80px);
    z-index: 100;
    border: 1px solid white;
    background: rgba(255, 255, 255, 0.01);
    border-radius: 40px;

    .ionicon svg {
        width: 24px;
        height: 24px;
    }

    //border-radius: 40px 40px 0px 0px;

    button {
        display: flex;
        flex-direction: column;

        gap: 10px;
    }

    ion-icon,
    .ionicon svg,
    svg,
    * > svg {
        width: 24px !important;
        height: 24px !important;
    }

    p {
        font-size: 10px;
        font-weight: 500;
        color: var(--text);
    }
}

.beta {
    font-weight: 700;
    color: var(--contrastColor);
    background: var(--background-break);
    padding: 5px;
    border-radius: 10px;
}

.working {
    font-weight: 700;
    color: var(--good);
    background: var(--background-break);
    padding: 5px;
    border-radius: 10px;
}

#hidden {
    display: none;
}

.posAbsolute {
    position: absolute;
    top: 0;
    left: 0;
}

.posRelative {
    position: relative;
}

//Top
.top-bar-button {
    border-radius: 0;
    min-width: 100px;
    width: stretch;
    height: 40px;

    text-align: center;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &:active {
        background: var(--background-break) black;
        transform: none;
    }

    &:hover {
        background: #ffaa001f;
    }
}

.top-bar-holder {
    gap: 0px;

    :first-child {
        border-radius: 20px 0px 0px 20px;
    }

    :last-child {
        border-radius: 0px 20px 20px 0px;
    }
}

.top-bar-holder-google-version {
    gap: 0px;
    //border-radius: 20px;
    //box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
    //overflow: auto;
    overflow: auto;
    height: 100%;
    min-height: 54px;
    border-bottom: #000000;
    width: 100%;
    //transform: translate(-20px, 0px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.089);
    background: black;
    box-shadow: #00000050 0px 2px 4px;

    @media (max-width: 800px) {
        background: var(--Theme-Color);
    }
}

.top-bar-button-google-version {
    backdrop-filter: none;
    background: transparent;
    width: max-content;
    white-space: nowrap;
    box-shadow: none;
    //border-inline: 1px solid var(--background-break);
    border-radius: 0px;
    padding-inline: 30px;
    transition: all 0.2s ease-in-out;
    height: 100%;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.089);
}

.top-bar-button-google-version.active {
    color: var(--contrastColor);
    border: none !important;
    //border-radius: 20px 20px 20px 20px;
    border-inline: 0px solid var(--background-break);
    background: none;
    outline: none;
    //border-bottom: 2px solid var(--contrastColor) !important;
}

.top-bar-button-google-version-underline {
    height: 0px;
    background: var(--contrastColor);
    border-radius: 20px 20px 0px 0px;
    width: 0%;
    transition: all 0.2s ease-in-out;
}

.top-bar-button-google-version-underline.active {
    height: 2px;
    width: 80%;
}

.top-bar-button.active {
    background: var(--contrastColor); //color-mix(in hsl, var(--background-break), black);
    color: var(--Theme-Color);
    font-weight: 700;
}

.recording {
    background: var(--bad);
}

td {
    padding: 10px;
}

tr {
    padding: 10px;
    border-bottom: 1px solid var(--background-break);
}

th {
    padding: 10px;
    border-bottom: 1px solid var(--background-break);
    background-color: #211f24;
    color: var(--diff-color);
}

.first-and-last-no-padding {
    padding: 0px;

    button {
        padding-left: 0px;
        padding-right: 0px;
        margin-inline: auto;
    }

    &:nth-child(0) {
        padding-left: 0px;
    }

    &:first-child {
        padding-left: 0px;
    }

    &:last-child {
        padding-right: 0px;
    }
}

//Header
.ion-page {
    flex-direction: row;
}

.pc-header {
    @media (max-width: 800px) {
        display: none;
    }
    overflow: visible;
    box-shadow: rgba(0, 0, 0, 0.24) 2px -2px 4px;
    z-index: 100;
    transition: width 0.2s ease-in-out;
    width: 300px;
    position: relative;
    background: black;
}

.phone-header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
}

.pc-header-wrapper {
    max-height: 100vh;
    height: 100%;
    width: 500px;
    overflow: auto;
    position: relative;
    pointer-events: none;

    & > * {
        pointer-events: auto;
    }
}

.pc-header-wrapper {
    ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
}

.minimize {
    margin-top: 10px;
    margin-right: 10px;
}

.pc-header-content {
    height: 100%;
    width: 300px;
    overflow: visible;
    display: flex;
    // flex apart
    justify-content: space-between;

    @media (max-width: 450px) {
        width: 100%;
        max-width: 100vw;//calc(100vw - 4em);
        padding: 2em;
        max-height: 100vh;
        overflow: auto;
    }
}

.pc-header-minimized {
    width: 80px;

    .minimize {
        margin-right: 0px;
    }
}

.pc-header-minimized .pc-header-content {
    width: 80px;
}

.header-transition-out {
    width: 0px;
}

.header-tab-button {
    //background: #211f24;
    margin-inline: 10px;
    background: none;
    color: var(--diff-color);
    border-radius: 0px;
    padding: 10px;
    padding-inline: 20px;
    //border-bottom: 1px solid var(--background-break);
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    max-width: 300px;
    //width:400px;

    &:active {
        transform: scale(1);
    }
    &:hover {
        background: #211f24;
        color: var(--contrastColor);
    }

    &.open {
        border-radius: 10px 10px 0px 0px;
    }

    /*&:hover > .headerButtonText {
        width: 100px;
    }*/
    @media (max-width: 800px) {
        width: 100%;
        max-width: 100%;
    }
}

.pc-header-minimized .header-tab-button {
    width: 80px;
}

.open-tab {
    border-bottom: 1px solid var(--contrastColor);
}

.active {
    border: #ffa800 solid 1px;
    color: #ffa800;
    background: #ffc34e29;

    &:hover {
        //background: rgba(255, 255, 255, 0.2);
        //animation: appearingBorder 500ms forwards;
        border: 1px solid #ffa800;
    }
}

.active-icon {
    color: #ffa800;
    fill: #ffa800;

    & > * {
        fill: #ffa800;
    }
}

.headerButtonText /*, .header-tab-button:focus > .headerButtonLeave*/ {
    overflow: hidden;
    animation: appearWidth 300ms forwards;

    > * {
        width: 200px;
        min-width: 200px;
    }
}

.headerButtonLeave {
    overflow: hidden;
    width: 200px;

    animation: leaveWidth 100ms forwards;
}

.headerButtonLeave,
.headerButtonText {
    width: 0px;
    position: absolute;
    background: rgb(33, 31, 36);
    border-radius: 0px 10px 10px 0px;
    left: 80%;
    transition: all 0.2s ease-in-out;
    height: 100%;

    > * {
        width: 200px;
        padding: 10px 20px;
    }
}

@keyframes appearWidth {
    0% {
        //background: black;
        width: 0px;
    }
    100% {
        //background: rgb(33, 31, 36);
        width: 200px;
    }
}

@keyframes leaveWidth {
    0% {
        background: rgb(33, 31, 36);
        width: 200px;
    }
    100% {
        background: black;
        width: 0px;
    }
}

.clickable {
    cursor: pointer;
}

.radius {
    border-radius: var(--default-border-radius);
}

input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 100px;
    height: 100px;
    border: none;
    cursor: pointer;

    transition: outline 0.2s ease-in-out;
}

input[type="color"]:hover::-webkit-color-swatch {
    outline: 1px solid var(--contrastColor);
}

input[type="color"]::-webkit-color-swatch {
    border-radius: 50%;
    border: 0px solid #000000;

    transition: all 0.2s ease-in-out;
}
input[type="color"]::-moz-color-swatch {
    border-radius: 50%;
    border: 0px solid #000000;

    transition: all 0.2s ease-in-out;
}

.wrap-on-phone {
    @media (max-width: 800px) {
        flex-wrap: wrap;
    }
}

.column-on-phone {
    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.stretch-width-on-phone {
    @media (max-width: 800px) {
        width: stretch;
    }
}

.status {
    padding: 10px 20px;
    border-radius: 20px;
    // Text always big letter first
    text-transform: capitalize;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.MuiPopper-root {
    background: var(--background-break) !important;
    //padding: 100px;
    padding: 0;
}*/

.noInputIconInteraction::-webkit-inner-spin-button,
.noInputIconInteraction::-webkit-calendar-picker-indicator {
    //display: none;
    //-webkit-appearance: none;
    color: "white";
    pointer-events: none;
}

.noInputIconInteractionOnPhone::-webkit-inner-spin-button,
.noInputIconInteractionOnPhone::-webkit-calendar-picker-indicator {
    @media (max-width: 800px) {
        //display: none;
        //-webkit-appearance: none;
        color: "white";
        pointer-events: none;
    }
}

.appBar {
    position: "relative";
    overflow: "hidden";
    bottom: 0 !important;
    top: auto !important;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 40px;
        border-radius: 50% 50% 0 0;
        background-color: black;
        z-index: 1;
    }
}

.ios-padding-top {
    padding-top: 60px;
}

.noWrapText {
    white-space: nowrap;
    //overflow: hidden;
    text-overflow: ellipsis;
}

.no-overflow {
    overflow: hidden;
}

.good {
    background-color: var(--good) !important;
    color: var(--text) !important;
}

// Modal

.effektiv-jobb-modal-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: stretch;
    height: stretch;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    backdrop-filter: blur(2px);

    .effektiv-jobb-modal {
        animation: appearFromBottom 0.2s ease-in-out forwards;
        background-color: var(--Theme-Color);
        border-radius: 10px;
        width: 90%;
        max-width: 800px;
        max-height: 90%;
        position: absolute;
        //overflow-y: auto;
        //overflow-x: hidden;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin: auto;

        @media (max-width: 1000px) {
            margin: 0px;
            position: absolute;
            width: 100%;
            height: 80%;
            bottom: 0;
            border-radius: 10px 10px 0px 0px;
        }

        .close-button {
            background: none;
            border: none;
            padding: 0;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                width: 24px;
                height: 24px;
                fill: var(--text);
            }
        }

        .modal-content {
            padding: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            .customInput {
                background: white;
                color: black;
            }
        }

        .modal-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            background: black;
            padding: 10px 20px;
            border-radius: 10px 10px 0px 0px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        }

        .modal-search-container {
            background: #00000050;
            border-radius: 50px;
            padding: 0px 20px;
        }

        .modal-card {
            background: rgba(0, 0, 0, 0.503);
            padding: 10px 40px;
            border-radius: 7px;
        }
    }
}

.effektiv-jobb-modal-background {
    //background: rgba(0, 0, 0, 1);
    backdrop-filter: blur(2px);
    z-index: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

@keyframes appearFromBottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

.division-line {
    width: 80%;
    height: 1px;
    background: var(--background-break);
    margin-inline: auto;
}

.division-line-vertical {
    width: 1px;
    height: 80%;
    margin-block: auto;
    background: var(--background-break);
}

.circle {
    background-color: var(--input-background);
    aspect-ratio: 1/1;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    transform: translate(-30%, -30%);
}

.card {
    background: var(--background-break);
    padding: 20px !important;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.alert-card {
    background: rgba(0, 0, 0, 0.506);
    backdrop-filter: brightness(20%);
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: min(90vw, 400px);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;

    border-radius: 10px;
    overflow: hidden;
    z-index: 9999;
    transform: translate(-50%, -50%);

    @media (min-width: 600px) {
        transform: translate(-50%, -50%);
    }
}

.alert-content {
    padding: 20px;
    background: var(--background-color);
}

.alert-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: black;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.alert-background {
    background: rgba(0, 0, 0, 0.252);
    backdrop-filter: blur(10px);
    z-index: 999;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.alert-text {
    color: var(--text);
    font-weight: 500;
    font-size: 12pt;
}

.alert-buttons {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.alert-buttons .orangeButton {
    font-weight: 700;
}

.fullscreen-modal {
    display: none;
}

.fullscreen-modal.open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
}

.divider {
    width: 100%;
    height: 1px;
    background: var(--background-break);
}

.divider-orange {
    width: 100%;
    height: 1px;
    background: var(--contrastColor);
}

.margin-bottom-content {
    margin-bottom: 100px;
}

.not-on-phone {
    @media (max-width: 800px) {
        display: none;
    }
}

.text-no-wrap {
    white-space: nowrap;
}

.hidden {
    display: none;
}