.math-input {
  background: white;
  color: black;
  font-size: 16pt !important;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.math-input-text {
  background: none !important;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font-size: 16pt !important;
}

.math-input-select {
  width: 5em;
}

.result {
  border-bottom: 1px solid var(--diff-color);
  padding: 10px;
}/*# sourceMappingURL=kalkulator.css.map */