.top-menu {
    input {
        font-size: 1.2em;
    }
    button {
        padding: 1em;
    }

    * > svg {
        width: 1.5em;
        height: 1.5em;
    }
}

.icon-only-button {
    background: none;
    border: none;
    box-shadow: none;
    border:none;

    &:hover {
        border: none;

        svg {
            // svg box shadow
            filter: drop-shadow(0 0 0.3em var(--contrastColor));
        }

    }

    &.active {
        color: var(--contrastColor);
        * {

            fill: var(--contrastColor);
        }

        &:hover {
            border: none;
    
            svg {
                // svg box shadow
                filter: drop-shadow(0 0 0.5em rgb(255, 255, 255));
            }
    
        }
    } 

    svg {
        width: 1.5em !important;
        height: 1.5em !important;
    }
}

.phone-header-button-icon {
    width: 1.5em !important;
    height: 1.5em !important;
}
