.message {
  padding: 1em;
  background: var(--background-break);
  border-radius: var(--default-border-radius);
  color: white;
}
.message > * {
  color: white;
}

.right-align .message {
  background: var(--contrastColor);
  color: black;
  margin-left: 30px;
}
.right-align .message > * {
  color: black;
}

.left-align .message {
  margin-right: 30px;
}

.myMessage {
  background: var(--diff-color);
  padding: 10px;
  border-radius: 10px;
  margin-left: 3em;
}
.myMessage > * {
  color: white;
  font-weight: 700;
}

.chat-holder {
  flex-direction: column-reverse;
  overflow-y: auto;
  height: calc(100% - 60px);
  max-height: 80vh;
  overflow: scroll;
}

.wrapper-for-typing-top {
  background-color: var(--input-background);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
}

.chatInput {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: var(--background-break);
}

.chatMessage {
  height: -moz-fit-content;
  height: fit-content;
}/*# sourceMappingURL=chat.css.map */