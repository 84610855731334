.selection {
    //background: white;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.firma-area {
    display: flex;
    /*:first-child {
        flex-grow: 2;
    }*/
}

.flex-grow-first {
    display: flex;
    /*:first-child {
        flex-grow: 2;
    }*/
}

.rapportCard {
    background: whitesmoke;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: black;
    padding: var(--default-padding);

    p, h1, h2, h3, h4, h5, h6 {
        color: black;
    }
}

.label {
    padding: 10px 20px;
    background: var(--diff-color);
    align-items: center;
    justify-content: center;
    height: stretch;
    color: white;
}

.flex-grow-inputs {
    display: flex;
    border-radius: 2px;
    overflow: hidden;

    & input {
        min-width: 100px;
        width: stretch;
        //max-width: 30%;
        //flex-grow: 4;
        border-radius: 0;
        height: 50px;
        box-shadow: 0;
        flex: 5;
    }

    & .label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        flex: 1;
        //flex-shrink: 2;
    }

    //flex: 3;
}

.oppgave {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--diff-color);
    padding-bottom: 20px;
}



.signer-button {
    position: fixed;
    bottom: 20px;
    right: 50%;
    transform: translate(50%, 0);

    //background-color: blue;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    //box-shadow: 0 0px 4px rgba(0, 0, 0, 0.891);
    border-radius: var(--default-border-radius);
    color: white;
    z-index: 2;
}

.canvas-container {
    display: flex;
    flex-direction: column;
    //margin-top: 20px;
    //margin-bottom: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    transition: all 0.2s ease-in-out;
    //djustify-content: center;
}

.canvas {
    width: 100%;
    //height: 40%;
    position: absolute;
    //top: 60px;
    left: 0;
    z-index: 1;
    background: white;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    z-index: 0;
    //transform: translateY(-50%);
}

.img-holder {
    width: fit-content;
}

.canvas-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 120px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) scale(1.3);
}

.canvas-slider-container {
    position: absolute;
    top: 10px;
    left: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    box-shadow: none;
    transform: translate(-50%, 0);

    > input {
        width: 100%;
        box-shadow: none;
    }
}

.bottomLine {
    position: absolute;
    bottom: 20px;
    left: 50%;
    height: 1px;
    width: 80%;
    background: black;
    transform: translateX(-50%);
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations {
    display: none !important;
}

.preview  {
    display: flex;
    overflow: scroll;
    margin-top: 20px;
}

.trash {
    width: 36px;
    height: 36px;

}

.search-results {
    display: flex;
    flex-direction: column;
}

.search-card {
    background: var(--background-color);
    border-radius: 5px;
    color: var(--text);
    //border-top: 1px solid black;
    overflow: hidden;
    

    p, h1, h2, h3, h4, h5, h6 {
        color: var(--text);
    }
}

.search-bilde {
    max-width: 70px;
    max-height: 70px;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.utstyr-container {
    background: var(--input-background);
    border-radius: 4px;
    min-height: 150px;
    align-content: flex-start;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    p, h1, h2, h3, h4, h5, h6 {
        color: var(--text);
    }
}

.rapportContainer {
    overflow: overlay;
    @media (min-width: 768px) {
        height: 85vh;
    }

}

.annet-area {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
}

.input-holder {
    width: calc(50% - 10px);
}

.task-specifics-row {
    padding-left: 40px;
}

.task-max-width-pc {
    max-width: max(25em, 40%);
}

.large-task-max-width-pc {
    max-width: max(25em, 60%);
}