/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
/** Ionic CSS Variables **/
:root {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  /*--color: white;
  --button-color: white;*/
  --ion-text-color: #ffffff;
  --background: transparent;
  --ion-background-color: #292929;
  --ion-background-color-rgb: 0, 15, 6;
  --ion-toolbar-color: white;
  --ion-toolbar-background: #000000;
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-primary: #e28f00;
  --ion-color-primary-rgb: 41, 41, 41;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #242424;
  --ion-color-primary-tint: #e28f00;
  --ion-color-secondary: white;
  --ion-color-secondary-rgb: 77, 91, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4450e0;
  --ion-color-secondary-tint: #5f6bff;
  --ion-color-tertiary: #4d5bff;
  --ion-color-tertiary-rgb: 77, 91, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4450e0;
  --ion-color-tertiary-tint: #5f6bff;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-medium: white;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: white;
  --ion-color-medium-tint: white;
  --ion-color-light: white;
  --ion-color-light-rgb: 232, 232, 232;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cccccc;
  --ion-color-light-tint: #eaeaea;
  --color-selected: #e28f00;
}

:host {
  --color: #ffffff;
}

ion-tabbar {
  --ion-color-primary: #fff;
}

ion-tabs {
  --ion-tabbar-background-color: #ffffff;
  --ion-tabbar-color-selected: var(--ion-text-color);
}

ion-icon,
ion-label {
  color: var(--ion-color-primary-contrast);
  --ion-color-base: var(--ion-color-secondary);
}

tab-selected {
  --color: var(--ion-color-primary);
}

.active-tab {
  --color: var(--ion-color-primary);
}

.tab-selected * {
  color: var(--ion-color-primary);
}

.select-text,
.select-icon {
  color: var(--ion-color-primary);
}

.select-interface-option {
  --color: var(--ion-color-primary);
}

ion-tab-bar {
  background: linear-gradient(180deg, #282426 0%, #211f24 100%);
  --background: transparent;
  padding: 20px 20px 25px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  border-radius: 40px 40px 0px 0px;
}
ion-tab-bar .ionicon svg {
  width: 32px;
  height: 32px;
}

ion-tab-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

ion-icon {
  width: 32px;
  height: 32px;
}

ion-select::part(icon) {
  display: none !important;
}

ion-datetime {
  --background: hsl(222, 19%, 11%);
  --background-rgb: 23, 26, 33;
  --wheel-highlight-background: hsl(222, 19%, 11%);
  --wheel-fade-background-rgb: 23, 26, 33;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--ion-color-primary);
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  max-width: 100%;
  height: 150px;
}
ion-datetime picker-before {
  background: linear-gradient(180deg, #282426 0%, #211f24 100%) !important;
}

.picker-before {
  background: linear-gradient(180deg, #282426 0%, #211f24 100%) !important;
}/*# sourceMappingURL=variables.css.map */