.selection {
  color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.firma-area {
  display: flex;
  /*:first-child {
      flex-grow: 2;
  }*/
}

.flex-grow-first {
  display: flex;
  /*:first-child {
      flex-grow: 2;
  }*/
}

.rapportCard {
  background: whitesmoke;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: black;
  padding: var(--default-padding);
}
.rapportCard p, .rapportCard h1, .rapportCard h2, .rapportCard h3, .rapportCard h4, .rapportCard h5, .rapportCard h6 {
  color: black;
}

.label {
  padding: 10px 20px;
  background: var(--diff-color);
  align-items: center;
  justify-content: center;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  color: white;
}

.flex-grow-inputs {
  display: flex;
  border-radius: 2px;
  overflow: hidden;
}
.flex-grow-inputs input {
  min-width: 100px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  border-radius: 0;
  height: 50px;
  box-shadow: 0;
  flex: 5;
}
.flex-grow-inputs .label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  flex: 1;
}

.oppgave {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--diff-color);
  padding-bottom: 20px;
}

.signer-button {
  position: fixed;
  bottom: 20px;
  right: 50%;
  transform: translate(50%, 0);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border-radius: var(--default-border-radius);
  color: white;
  z-index: 2;
}

.canvas-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.canvas {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  z-index: 0;
}

.img-holder {
  width: -moz-fit-content;
  width: fit-content;
}

.canvas-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 120px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%) scale(1.3);
}

.canvas-slider-container {
  position: absolute;
  top: 10px;
  left: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  box-shadow: none;
  transform: translate(-50%, 0);
}
.canvas-slider-container > input {
  width: 100%;
  box-shadow: none;
}

.bottomLine {
  position: absolute;
  bottom: 20px;
  left: 50%;
  height: 1px;
  width: 80%;
  background: black;
  transform: translateX(-50%);
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations {
  display: none !important;
}

.preview {
  display: flex;
  overflow: scroll;
  margin-top: 20px;
}

.trash {
  width: 36px;
  height: 36px;
}

.search-results {
  display: flex;
  flex-direction: column;
}

.search-card {
  background: var(--background-color);
  border-radius: 5px;
  color: var(--text);
  overflow: hidden;
}
.search-card p, .search-card h1, .search-card h2, .search-card h3, .search-card h4, .search-card h5, .search-card h6 {
  color: var(--text);
}

.search-bilde {
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;
  -o-object-fit: cover;
     object-fit: cover;
}

.utstyr-container {
  background: var(--input-background);
  border-radius: 4px;
  min-height: 150px;
  align-content: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.utstyr-container p, .utstyr-container h1, .utstyr-container h2, .utstyr-container h3, .utstyr-container h4, .utstyr-container h5, .utstyr-container h6 {
  color: var(--text);
}

.rapportContainer {
  overflow: overlay;
}
@media (min-width: 768px) {
  .rapportContainer {
    height: 85vh;
  }
}

.annet-area {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
}

.input-holder {
  width: calc(50% - 10px);
}

.task-specifics-row {
  padding-left: 40px;
}

.task-max-width-pc {
  max-width: max(25em, 40%);
}

.large-task-max-width-pc {
  max-width: max(25em, 60%);
}/*# sourceMappingURL=rapporter.css.map */